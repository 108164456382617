import "@styles/app.sass"

import Swiper, {Navigation, Scrollbar} from "swiper";
import lightGallery from "lightgallery";

(() => { // Hiding header
    const header = document.querySelector('[data-app-header]') as HTMLElement;
    const headerOffset = header.offsetTop - window.scrollY,
        headerHeight = header.offsetHeight ?? 0;

    let lastScroll = 0;

    const hidingOnScroll = () => {
        const currentScroll = window.scrollY;

        if (currentScroll > headerOffset + headerHeight) {
            header.classList.add('is-sticked')
            header.classList[currentScroll > lastScroll ? 'add' : 'remove']('is-up')
        } else {
            header.classList.remove('is-sticked', 'is-up')
        }

        lastScroll = currentScroll;
    }

    hidingOnScroll();
    window.addEventListener('scroll', () => hidingOnScroll(), { passive: true });
})();

(() => { // Mobile header toggle
    const menuToggle = document.querySelector('[data-nav-opener]');
    if (!menuToggle) return;

    const toggleMenu = ev => {
        ev.preventDefault();
        document.body.classList.toggle('u-hiddenOverflow');
        document.querySelector('[data-app-header]').classList.toggle('is-opened');
    };

    menuToggle.addEventListener('click', toggleMenu);

    const links = document.querySelectorAll('[data-app-nav] a');
    if (!links) return;

    links.forEach(link => {
        link.addEventListener('click', () => {
            document.body.classList.remove('u-overflowHidden');
            document.querySelector('.AppHeader').classList.remove('is-open');
        });
    })
})();

(() => { // notices swiper
    const notices = document.querySelector('[data-notices-swiper]') as HTMLElement;
    if (!notices) return;

    new Swiper(notices, {
        slidesPerView: 1,
        spaceBetween: 24,
        modules: [ Navigation, Scrollbar ],
        breakpoints: {
            512: {
                slidesPerView: 2
            },
            768: {
                slidesPerView: 3
            },
            1280: {
                slidesPerView: 4,
                spaceBetween: 32
            }
        },
        navigation: {
            nextEl: '[data-notices-next]',
            prevEl: '[data-notices-prev]',
        },
        scrollbar: {
            el: '[data-notices-scrollbar]',
            draggable: true,
            hide: false
        },
    });
})();

(() => { // pageContent gallery
    const galleries = document.querySelectorAll('.wp-block-gallery');
    galleries.forEach((gallery: HTMLElement) => lightGallery(gallery, {
        selector: 'figure > a',
        speed: 400,
        download: false,
        getCaptionFromTitleOrAlt: false,
        mobileSettings: {
            showCloseIcon: true
        }
    }));
})();

(() => { // single PARTE zoom
    const parte = document.querySelector('[data-parte-thumbnail]') as HTMLElement;
    parte && lightGallery(parte, {
        speed: 400,
        download: false,
        getCaptionFromTitleOrAlt: false,
        mobileSettings: {
            showCloseIcon: true
        }
    });
})();